import { RemixBrowser } from '@remix-run/react'
import { startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { trackVersion } from '#app/utils/version-check'

if (ENV.MODE === 'production' && ENV.DD_ENV) {
	import('./utils/monitoring-datadog.client.js')
		.then(({ init }) => init())
		.catch(console.error)
}

startTransition(() => {
	hydrateRoot(document, <RemixBrowser />)
})

// Intercept responses on the client side
const originalFetch = window.fetch
window.fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
	const response = await originalFetch(input, init)
	const newVersion = response.headers.get('X-App-Version')
	if (newVersion) {
		trackVersion(newVersion)
	}
	return response
}
